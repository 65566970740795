// @flow
import type { Version } from 'flow/version';

const logs: Array<Version> = [
  {
    version: '4.1.3',
    releaseDate: 'Tuesday, April 9, 2024',
    fixes: ['Add Invoice payment type '],
  },
  {
    version: '4.1.2',
    releaseDate: 'Thursday, February 16, 2023',
    fixes: ['Enable inspection app only for admins '],
  },
  {
    version: '4.1.1',
    releaseDate: 'Friday, July 22, 2022',
    fixes: ['Fix Brand inspectors not able to view certificate past 2 month data '],
  },
  
  {
    version: '4.1.0',
    releaseDate: 'Friday, July 8, 2022',
    fixes: ['Fix print button issue with chrome browser on ipads '],
  },
  {
    version: '4.0.9',
    releaseDate: 'Friday, June 24, 2022',
    fixes: ['Fix number not getting generated '],
  },
  {
    version: '4.0.8',
    releaseDate: 'Thursday, June 23, 2022',
    fixes: ['Fetching two month data for admins '],
  },
  {
    version: '3.22.2',
    releaseDate: 'Wednesday, June 30, 2021',
    fixes: ['adding staging and production square application ids'],
  },
  {
    version: '3.22.1',
    releaseDate: 'Monday, June 28, 2021',
    fixes: ['handle credit card payments for brand inspections'],
  },
  {
    version: '3.21.4',
    releaseDate: 'Thursday, June 17, 2021',
    fixes: [
      'optimize printed travel and presale permits for using the new 30 day remote image policy. This means that travel permit and presale certificate photos will not be available offline after 30 days.',
      'updated certificates to enable automatic testing before production releases',
    ],
    features: [
      'upgraded deployment pipeline from Helm 2 to Helm 3, required for deployment',
    ],
  },
  {
    version: '3.19.2',
    releaseDate: 'Wednesday, June 2, 2021',
    fixes: [
      'updated travel permits and cards to print on one page',
      'updated presale permits to print on one page',
      'minor improvements to inspections certificate one page rendering',
      'security updates',
      'fixed the link to presale certificates in the admin section listing all presales',
      'speeding up search functionality',
      'added missing data on travel permits',
      'improved offline first page loading',
    ],
  },
  {
    version: '3.18.0',
    releaseDate: 'Thursday, May 13, 2021',
    fixes: [
      'improved the application loading speed after this update',
      'improved post-inspection certificate payment form',
      'removed the payment status column for Brand Inspectors until it is more useful',
      'implemented consistent sorting for inspection types',
      'removed GPS coordinates from the certificates',
    ],
  },
  {
    version: '3.17.17',
    releaseDate: 'Tuesday, May 3, 2021',
    fixes: [
      'additional printing improvements for brand certificates',
      'additional inspection minimum fee bug fixes',
      'fixing issues with past inspection totals not displaying correct total because waived fees were not included',
      'reformatting the display of adding and removing waived and overridden fees',
    ],
  },
  {
    version: '3.17.9',
    releaseDate: 'Friday, April 23, 2021',
    fixes: [
      'fixed inspection certificates bug that cut-off the side of the certificate when a very large number of brands were used on one line item',
      'the receiving station certificate no longer re-states the inspection fee and inspection total',
      'the minimum fee is now being presented correctly on certificates that are under the certificate minimum',
    ],
  },
  {
    version: '3.17.8',
    releaseDate: 'Thursday, April 22, 2021',
    fixes: [
      'fixed inspection certificates bug that cut-off the side of the certificate when printed from an ipad held in landscape position',
    ],
  },
  {
    version: '3.17.7',
    releaseDate: 'Wednesday, April 21, 2021',
    fixes: [
      'fixed a bug that caused the $20.00 inspection minimum to be waived if the inspector waived a single line item inspection fee on a brand inspection with multiple line items',
      'added premise number on brand inspection printed certificates',
    ],
    features: [
      'new single page printed format for brand inspection certificates',
      'added voided stamp to voided certificates',
    ],
  },
  {
    version: '3.16.2',
    releaseDate: 'Wednesday, April 14, 2021',
    fixes: [
      'fixed a bug that resulted in a 500 error when you reload the page on the preview screen',
      'carrier now shows up on the printed inspection appropriately',
    ],
  },
  {
    version: '3.16.1',
    releaseDate: 'Friday, April 9, 2021',
    fixes: [
      'draft inspections started on a previous day will now reflect the date the inspection was finally saved, not the date the inspection was started',
      'minor security and optimization fixes',
    ],
    features: [
      'now you can waive the minimum fee for brand inspections by clicking the minimum fee field',
      'updated the recap report to exclude inspections marked for pay later',
    ],
  },
  {
    version: '3.15.1',
    releaseDate: 'Thursday, April 1, 2021',
    fixes: [
      'fixed accuracy of wifi to prevent the app from mistakenly syncing while offline',
      'expired login will now correctly redirect the user to the login page',
      'calf-pool receiving station inspections no longer permit animal headcount',
      'consignment and production sales no longer display a "paid in office" message',
      'improved gps location',
      'removed the "Registration No." field from step one of the brand inspection form',
      'removed predator and beef council fee fields on non-beef inspections',
      'removed the pay button from public certificates',
    ],
    features: [
      'consignment and production sales now require that you only add headcount over the paid-in-office minimum, for example, the total headcount displayed on a consignment certificate for 95 head of beef will be five',
    ],
  },
  {
    version: '3.11.3',
    releaseDate: 'Tuesday, March 23, 2021',
    fixes: [
      'fixed sorting of brand inspections, ie, inspections from December are no longer displayed first',
      'fixed typo "This is certificate void if altered" on the second step of brand inspections form',
      'removed text "Required by UTAH State Law" from the Dealers # field on the second step of the brand inspection form',
    ],
  },
  {
    version: '3.11.1',
    releaseDate: 'Monday, March 22, 2021',
    fixes: [
      'improved speed of initial application load and when synchronizing inspections',
    ],
  },
  {
    version: '3.10.4',
    releaseDate: 'Saturday, March 20, 2021',
    fixes: ['fixed error when searching inspections by date range'],
  },
  {
    version: '3.10.2',
    releaseDate: 'Friday, March 19, 2021',
    fixes: [
      'restarted all inspection certificate numbers at 000001 for every inspector, with the following new format',
      'changed inspection codes to UDAF-[inspector id]-[inspection certificate number sequence per inspector]-[inspection type], e.g. UDAF-17521-000001-BI',
      'updated software dependencies with minor security & bug fixes',
      'fixed a bug in the inspection error logger to provide more info',
    ],
  },
  {
    version: '3.9.3',
    releaseDate: 'Monday, March 15, 2021',
    fixes: [
      'no Internet connection is required to settle payments after certificates are issued',
      'Inspectors are no longer be able to change travel permits and presale inspections after they are printed, with the exception of adding payment information',
      'fixed an inconsistency in inspection certificate numbering across inspections',
    ],
    features: [
      'admin users can search inspections by inspector name, buyer name and payment type',
      'certificates now display the payer name and check number if available',
    ],
  },
  {
    version: '3.7.3',
    releaseDate: 'Sunday, March 14, 2021',
    fixes: [
      'travel permits and presales now redirect to the print page following completion',
      'travel permits and presales now display the owner or seller phone number',
    ],
  },
  {
    version: '3.7.1',
    releaseDate: 'Friday, March 12, 2021',
    fixes: [
      'Inspectors can now access unpaid certificates in offline mode',
      'Inspectors can now see more than three pages of inspections',
      'fixed bug preventing presale print display',
      'fixed bug which may have allowed consecutive travel permits to overwrite the previous',
      'fixed county sorting to be alphabetic',
      'fixed various typos',
    ],
    features: [
      'added version details for each version upgrade',
      'when inspectors attempts to logout when the application is offline, they will receive a warning',
      'when "pay later" is selected, the inspector can note the payers name',
      'inspection codes will now be consecutive, without gaps',
      'improved animal line item descriptions, ie, "8 Black Balancer Heifers with brands: x, y, z" or "A 15 year old Bull Elk with a Green Tag #123..."',
      'inspectors may now filter their inspections by payment type',
      'inspections now sorted by the most recent inspections at the top of the list',
      'certificates and travel permits now display signature lines',
      'added client and server versions to printed certificates',
    ],
  },
  {
    version: '2.7.2',
    releaseDate: 'Wednesday, February 24, 2021',
    fixes: [
      'Presale/Travel forms now redirect to certificate in offline mode',
      'corrected pricing for for calf-pool receiving certificate',
      'corrected horse/cattle inspection pricing for consignment/production sales',
      'saving a brand inspection no longer displays a "failed" message on printed certificates',
      'fixed several bugs in the sync process',
    ],
    features: [
      'removed loud minimum inspection fee notice and replaced it with a minimum fee footnote if the inspection is below minimum',
      'improved field validation to supply more visible messages when there are problems with the form',
      'every inspection save will attempt to synchronize all inspections if the application is online',
    ],
  },
  {
    version: '2.5.0',
    releaseDate: 'Wednesday, February 17, 2021',
    features: [
      'added menu item for viewing "Unpaid" inspections',
      'inspectors can now change the payment on an inspection or permit that was previously marked "pay later"',
      'horse presale form no longer requires a horse name',
    ],
  },
  {
    version: '2.1.1',
    releaseDate: 'Monday, February 15, 2021',
    fixes: [
      'fixed a problem where inspectors were randomly experiencing 500 errors',
      'fixed a number of pricing corrections',
    ],
    features: [
      'admins can now view all past inspections, travel permits and presales',
    ],
  },
];

export default logs;
